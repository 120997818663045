import localFont from "@next/font/local";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import {
	SharedLoadingIndicatorContextProvider,
	SharedProgressLoadingIndicator,
} from "shared-loading-indicator";
import { PageNavigationLoadingTracker } from "../components/PageNavigationLoadingTracker";
import "../styles/globals.sass";
import { api } from "../utilities/api";

export const ClashGrotesk = localFont({
	src: [
		{
			path: "../styles/fonts/ClashGrotesk-Regular.woff2",
			weight: "400",
			style: "normal",
		},
		{
			path: "../styles/fonts/ClashGrotesk-Medium.woff2",
			weight: "500",
			style: "normal",
		},
		{
			path: "../styles/fonts/ClashGrotesk-SemiBold.woff2",
			weight: "600",
			style: "normal",
		},
		{
			path: "../styles/fonts/ClashGrotesk-Bold.woff2",
			weight: "700",
			style: "normal",
		},
	],
	display: "swap",
});

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter();

	return (
		<>
			<SharedLoadingIndicatorContextProvider>
				<SharedProgressLoadingIndicator />
				<PageNavigationLoadingTracker />
				<style jsx global>{`
					:root {
						--font-clash-grotesk: ${ClashGrotesk.style.fontFamily};
						--font-body: var(--font-clash-grotesk);
						--font-heading: var(--font-body);
					}
					html {
						font-family: ${ClashGrotesk.style.fontFamily};
					}
				`}</style>
				<Component {...pageProps} />
			</SharedLoadingIndicatorContextProvider>
		</>
	);
}

export default api.withTRPC(MyApp);
